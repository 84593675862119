<div class="chex-bottom_space">
    <ruf-page-header role="region"  rufId="page_header_disputeTab_title" title="{{ 'CHANGE PASSWORD.TITLE' | translate }}" description="">
    </ruf-page-header>
    <ruf-statusbar class="chex-page-left-right-alignment statusbar-password-note" color="info" [closeable]="false"
        [border]="false" [icon]="'information'">
        <div role="region" class="mat-body-2">
            The following rules apply for New Password and Confirm New Password:
            <br />
            The Password must contain the following: <br />
            12 – 32 characters including uppercase letters, lowercase letters, numbers,
            special characters.<br />
            (Special characters include one of the following ! @ # $ % ^ & +) <br />
            The Password cannot contain your username, in forward or
            reverse order. <br />
            The Password cannot contain your first, middle or last name, in
            forward or reverse order.
        </div>
    </ruf-statusbar>
    <form [formGroup]="changePasswordFormGroup" id="changePasswordFormGroup" autocompleteOff>
        <div class="chex-page-left-right-alignment">
            <mat-form-field role="region" aria-label="currentpswd" boxStyle floatLabel="always" class="boxed-input-medium-width">
                <mat-label id="currentLabel">{{ 'CHANGE PASSWORD.CURRENT PASSWORD' | translate }}</mat-label>
                <input [type]="currentPasswordType" id="currentPwd" formControlName="currentpwdCtrl" fisStyle matInput
                    placeholder="Enter Current Password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+])(?!.*\s).+$" minlength="8"
                    maxlength="32" autocompleteOff>
                <mat-error *ngIf="changePasswordFormGroup.get('currentpwdCtrl')?.hasError('required')">{{ 'CHANGE PASSWORD.CURRENT PASSWORD ERR' | translate }}</mat-error>
                <mat-error
                    *ngIf="changePasswordFormGroup.get('currentpwdCtrl')?.errors?.pattern || changePasswordFormGroup.get('currentpwdCtrl')?.errors?.minlength || changePasswordFormGroup.get('currentpwdCtrl')?.errors?.maxlength || changePasswordFormGroup.controls['currentpwdCtrl']?.errors?.isInValid"
                    matTooltip="{{invalidErrorMessage.currentpassword}}" matTooltipPosition="below"
                    [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0"
                    [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>
                    Invalid Password. Hover for details.</mat-error>
            </mat-form-field>
            <span id="currentPwdMasking" class="eyeIcon">
                <mat-icon [matTooltip]="currentPasswordEyeIconTooltip" #maskEyePassword [fisIcon]="currentPasswordEyeIconType" (click)="_handleCurrentMasking('password', maskEyePassword)"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" style="font-size: 20px;"></mat-icon>
              </span>
        </div>
        <div class="chex-page-left-right-alignment">
            <mat-form-field role="region" aria-label="newpswd" class="boxed-input-medium-width" boxStyle floatLabel="always">
                <mat-label id="newLabel">{{ 'CHANGE PASSWORD.NEW PASSWORD' | translate }}</mat-label>
                <input [type]="newPasswordType" id="newPwd" formControlName="pwdCtrl" fisStyle matInput placeholder="Enter New Password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+])(?!.*\s).+$" minlength="12"
                    maxlength="32" autocompleteOff>
                <mat-error *ngIf="changePasswordFormGroup.get('pwdCtrl')?.hasError('required')">{{ 'CHANGE PASSWORD.NEW PASSWORD ERR1' | translate }}</mat-error>
                <mat-error *ngIf="changePasswordFormGroup.get('pwdCtrl')?.errors?.shouldNotMatch">{{ 'CHANGE PASSWORD.NEW PASSWORD ERR2' | translate }}</mat-error>
                <mat-error
                    *ngIf="changePasswordFormGroup.get('pwdCtrl')?.errors?.pattern || changePasswordFormGroup.get('pwdCtrl')?.errors?.minlength || changePasswordFormGroup.get('pwdCtrl')?.errors?.maxlength || changePasswordFormGroup.controls['pwdCtrl']?.errors?.isInValid"
                    matTooltip="{{invalidErrorMessage.changepassword}}" matTooltipPosition="below"
                    [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0"
                    [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>
                    Invalid Password. Hover for details.</mat-error>
            </mat-form-field>
            <span id="newPwdMasking" class="eyeIcon">
                <mat-icon [matTooltip]="newPasswordEyeIconTooltip" #maskEyeNewPassword [fisIcon]="newPasswordEyeIconType" (click)="_handleNewPasswordMasking('password', maskEyeNewPassword)"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" style="font-size: 20px;"></mat-icon>
              </span>
        </div>
        <div class="chex-page-left-right-alignment">
            <mat-form-field role="region"  class="boxed-input-medium-width" boxStyle floatLabel="always" required>
                <mat-label id="confirmLabel">{{ 'CHANGE PASSWORD.CONFIRM NEW PASSWORD' | translate }}</mat-label>
                <input [type]="confirmPasswordType" formControlName="confirmpwdCtrl" fisStyle matInput
                    placeholder="Enter Confirm New Password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+])(?!.*\s).+$" minlength="12"
                    maxlength="32" autocompleteOff>
                <mat-error *ngIf="changePasswordFormGroup.get('confirmpwdCtrl')?.hasError('required')">{{ 'CHANGE PASSWORD.CONFIRM NEW PASSWORD ERR1' | translate }}</mat-error>
                <mat-error *ngIf="changePasswordFormGroup.controls['confirmpwdCtrl'].errors?.mustMatch">{{ 'CHANGE PASSWORD.CONFIRM NEW PASSWORD ERR2' | translate }}</mat-error>
                <mat-error
                    *ngIf="changePasswordFormGroup.get('confirmpwdCtrl')?.errors?.pattern || changePasswordFormGroup.get('confirmpwdCtrl')?.errors?.minlength || changePasswordFormGroup.get('confirmpwdCtrl')?.errors?.maxlength || changePasswordFormGroup.controls['confirmpwdCtrl']?.errors?.isInValid"
                    matTooltip="{{invalidErrorMessage.changepassword}}" matTooltipPosition="below"
                    [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0"
                    [matTooltipClass]="{'demo-red-tooltip': showExtraClass, 'fis-style': false}" fisStyle>
                    Invalid Password. Hover for details.</mat-error>
            </mat-form-field>
            <span id="confirmPwdMasking" class="eyeIcon">
                <mat-icon [matTooltip]="confirmPasswordEyeIconTooltip" #maskEyeConfirmPassword [fisIcon]="confirmPasswordEyeIconType" (click)="_handleConfirmPasswordMasking('password', maskEyeConfirmPassword)"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" style="font-size: 20px;"></mat-icon>
              </span>
        </div>      

        <div class="chex-page-left-right-alignment" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="10px" rufMarginVertical rufId>
            <button role="region"  mat-raised-button fisStyle color="primary" id="submitBtn" class="form_submit_button" rufId ruf-stepper-actions
                type="submit" (click)="ChangePassword()" [attr.form]="changePasswordFormGroup">{{ 'BUTTONS.CHANGE PASSWORD' | translate }}</button>
            <button role="region" aria-label="cancel" mat-stroked-button fisStyle class="form_button" id="cancelBtn" type="button" (click)="Cancel()">{{ 'BUTTONS.CANCEL' | translate }}</button>
        </div>
    </form>
</div>