import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AUTH_NO_INTERCEPT_HEADER, RufAuthConfig } from '@ruf/idp-auth';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
const CryptoJS = require("crypto-js");

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  static idky1: any;
  config: { auth: RufAuthConfig };
  private readonly Api_Base_URL = environment.idpEndpoint;
  constructor(private http: HttpClient, private apiService: ApiService) { 
  }
 
  static getAesData() {
    this.idky1 = '4286552e-b9f7-4eee-937d-8777ac71a829';
    return this.idky1;
  }

  async load(): Promise<any> {
    const url = this.Api_Base_URL.concat('/cnfchk');
    // './././assets/jsondata/config-dev.json'
    // Would come from a backend's REST service in a real app
    return await lastValueFrom(
      this.http.get<{ auth: RufAuthConfig }>(url, {
        headers: {[AUTH_NO_INTERCEPT_HEADER]: 'yes', 'iddtl1': environment.API_KEY },
      })
    ).then((cfg) => (this.config = cfg));
  }

  static aesDecrypt(data: any) {
    try {
      let pK = ConfigurationService.getAesData();
      const bytes = CryptoJS.AES.decrypt(data, pK);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      throw error;
    }
  }

  static aesEncrypt(data: any) {
    try {
      // let pK = ConfigurationService.getAesData();
      // const ciphertext = CryptoJS.AES.encrypt(
      //   JSON.stringify(data),
      //   pK,
      // ).toString();
      // console.log(ciphertext);
      // return ciphertext;
    } catch (error) {
      throw error;
    }
  }
  }

