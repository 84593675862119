import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Don't like animations? Replace this with NoopAnimationsModule
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { PlaceSecurityAlertComponent } from './security-alerts/place-security-alert.component';

import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  RufTimePickerModule,
  RufNativeDateTimeModule,
  RufStepperModule,
} from '@ruf/widgets';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RufMaterialModule } from '@ruf/material';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { MatNativeDateModule } from '@angular/material/core';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { MatListModule } from '@angular/material/list';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { RufAvatarModule } from '@ruf/widgets';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { RufDynamicDatatableModule } from '@ruf/widgets';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';

import {
  RufAppCanvasModule,
  RufLayoutModule,
  RufBannerModule,
  RufFooterModule,
  RufMenubarModule,
  RufNavbarModule,
  RufPageHeaderModule,
  RufCardModule,
  RufIconModule,
  RufLabeledIconModule,
  RufShellIntl,
  RufUniqueIdModule,
  RufToggleModule,
  RufDynamicMenubarModule,
  RufDialogPanelModule,
  RufDropdownPanelModule,
  RufTriggerModule,
  RufToolbarModule,
  RufScrollbarModule,
  RufStatusbarModule,
  RufEllipsisModule,
  RufDynamicSideMenuModule,
  RufDynamicSegmentModule,
} from '@ruf/shell';

import { AuthModule, AuthService } from '@ruf/idp-auth';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RegistrationComponent } from './registration/registration.component';
import { PhonecontrolComponent } from './common/controls/phone-control/phone-control.component';
import { MenuOnHeaderComponent } from './common/header-main/before-loggedin-menu/before-loggedin-menu.component';
import { TopHeaderComponent } from './common/header-main/before-loggedin-header/before-loggedin-header.component';
import { DialogwithmessageComponent } from './dailog/dialog-with-message/dialog-with-message.component';
import { BidiModule } from '@angular/cdk/bidi';
import { QuizTimerComponent } from './common/quiz-timer/quiz-timer.component';
import { ChxFooterComponent } from './common/footer/chx-footer.component';
import { DashboardDisputesComponent } from './common/dashboard-common/disputes-main/dashboard-disputes.component';
import { DashboardLandingComponent } from './common/dashboard-common/dashboard-landing/dashboard-landing.component';
import { DialogtermsandconditionsComponent } from './dailog/dialog-terms-and-conditions/dialog-terms-and-conditions.component';
import { MaskPipe } from './common/pipe/mask.pipe';
import { RufCarouselModule } from '@ruf/widgets';
import { RufUploadModule } from '@ruf/widgets';
import { DisputesDetailAllComponent } from './common/dashboard-common/disputes-main/disputes-detail-all/disputes-detail-all.component';
import { CorrespondenceAllComponent } from './common/dashboard-common/dashboard-correspondence/correspondence-all/correspondence-all.component';
import { DialogFileUploaderComponent } from './dailog/dialog-attachment/dialog-file-attachment.component';
import { PdfViewerComponent } from './common/pdfviewer/pdf-doc-viewer.component';

import { SecurityValidationService } from './_services/registration/security-validations.service';
import { ApiService } from './_services/shared/api.service';

import { DashboardCorrespondenceComponent } from './common/dashboard-common/dashboard-correspondence/dashboard-correspondence.component';

import { DashboardReportsComponent } from './common/dashboard-common/reports/dashboard-reports.component';
import { DialogsecurityfreezepinComponent } from './dailog/dialog-security-freeze-pin/dialog-security-freeze-pin.component';
import { DialogsecurityalertquestionComponent } from './dailog/dialog-security-alert-question/dialog-security-alert-question.component';
import { DialogagevalidityComponent } from './dailog/dialog-age-validity/dialog-age-validity.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
  ReCaptchaV3Service,
} from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { DialogIdleTimerComponent } from './dailog/dialog-idle-timer/dialog-idle-timer.component';
import { RecentComponent } from './common/correspondences/recent/recent.component';
import { MonthComponent } from './common/correspondences/month/month.component';
import { CorrespondenceComponent } from './common/correspondences/correspondence/correspondence.component';
import { ReminderComponent } from './common/correspondences/reminder/reminder.component';
import { UserprofileComponent } from './user-profile/user-profile.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { AfterLoginMenuOnHeaderComponent } from './common/header-main/loggedin-menu/after-login-menu.component';
import { AfterLoginTopHeaderComponent } from './common/header-main/loggedin-header/after-login-header.component';
import { ConsumerDisclosureDisputeComponent } from './consumer-disclosure-dispute/consumer-disclosure-dispute.component';
import { DialogdisputeComponent } from './dailog/dialog-dispute/dialog-dispute.component';
import { DialogdisclosureComponent } from './dailog/dialog-disclosure/dialog-disclosure.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DialogReportedInfoComponent } from './dailog/dialog-ReportedInfo/dialog-ReportedInfo.component';
import { DialogIndividualDisputeComponent } from './dailog/dialog-individual-dispute/dialog-individual-dispute.component';
import { ViewAllDocumentsComponent } from './common/dashboard-common/disputes-main/view-all-documents/view-all-documents.component';
import { ManageSecurityFreezeComponent } from './manage-freeze/manage-security-freeze.component';
import { ManageFreezeDetailsComponent } from './manage-freeze/manage-freeze-details/manage-freeze-details.component';
import { SecurityFreezeInfoComponent } from './security-freeze/security-freeze-info/security-freeze-info.component';
import { SecurityFreezeNoticeComponent } from './security-freeze/security-freeze-notice/security-freeze-notice.component';
import { PlaceFreezeComponent } from './place-freeze/place-freeze.component';
import { ReplaceSecurityPinComponent } from './replace-security-pin/replace-security-pin.component';
import { DialogwithmessagebtnComponent } from './dailog/dialog-with-message-btn/dialog-with-message-btn.component';
import { ConsumerScoreDisclosureReportComponent } from './consumer-score-disclosure-report/consumer-score-disclosure-report.component';
import { ConsumerScorePageComponent } from './consumer-score-disclosure-report/consumer-score-page/consumer-score-page.component';
import { DisclosureDisputeTransComponent } from './consumer-disclosure-dispute/disclosure-dispute-trans/disclosure-dispute-trans.component';
import { DialogRemFrzComponent } from './dailog/dialog-rem-frz/dialog-rem-frz.component';
import { DialogSchLiftComponent } from './dailog/dialog-sch-lift/dialog-sch-lift.component';
import { PhonenumbercontrolComponent } from './common/controls/phone-number-control/phone-number-control.component';
import { OptOutComponent } from './opt-out/opt-out.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConsumerDisclosureTransitionComponent } from './consumer-disclosure-transition/consumer-disclosure-transition.component';
import { IdentityTheftInformationComponent } from './identity-theft-information/identity-theft-information.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SitecoresearchComponent } from './sitecore/sitecore-search/sitecore-search.component';
import { SitecoreService } from './_services/sitecore/sitecore.service';
import { SanitizeHtmlPipe } from './common/pipe/sanitize-html.pipe';
import { AutocompleteOffDirective } from './common/autocomplete-off/autocomplete-off.directive';
import { SnackbarComponent } from './common/snackbar/snackbar/snackbar.component';
import { CacheInterceptor } from './_interceptors/cache.interceptor';
import { DialogSubmitDisputeTransComponent } from './dailog/dialog-submit-dispute-trans/dialog-submit-dispute-trans.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './_interceptors/errorInterceptor';
import { DialogIdleTimerAfterLoginComponent } from './dailog/dialog-idle-timer-after-login/dialog-idle-timer-after-login.component';
import { LoaderInterceptor } from './_interceptors/loader.interceptor';
import { LoaderComponent } from './common/loader/loader/loader.component';
import { CommonSnackbarComponent } from './common/common-snackbar/common-snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogwithmessageCancelComponent } from './dailog/dialog-with-message-cancel-btn/dialog-with-message-cancel-btn.component';
import { DialogWithMessageCustomButtonComponent } from './dailog/dialog-with-message-custom-button/dialog-with-message-custom-button.component';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { MyTokenStore } from './_services/shared/my-token-store.service';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { GlobalErrorHandler } from './_services/shared/global-error-handler.service';
import { ErrorHandler } from '@angular/core';
import {MatSortModule} from '@angular/material/sort';
import { DLMaskingDirective } from './common/dl-masking/dl-masking.directive';
import { HumanTraffickingComponent } from './human-trafficking/human-trafficking.component';
import { ConfigurationService} from './_services/shared/configuration.service';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const initApp = (
  config: ConfigurationService,
  authService: AuthService
) => () =>
  config.load().then((cfg) => {
    // delays initialization until config gets loaded
  //  setTimeout(() => {
  //   cfg.auth.dummyClientSecret = ConfigurationService.aesDecrypt( cfg.auth.dummyClientSecret);
  //   authService.setup(cfg.auth);
  //  }, 1000);
    
  });

@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    PhonecontrolComponent,
    TopHeaderComponent,
    MenuOnHeaderComponent,
    QuizTimerComponent,
    ChxFooterComponent,
    DashboardDisputesComponent,
    DashboardLandingComponent,
    DialogwithmessageComponent,
    DialogtermsandconditionsComponent,
    MaskPipe,
    DashboardCorrespondenceComponent,

    DashboardReportsComponent,
    DialogsecurityfreezepinComponent,
    DialogsecurityalertquestionComponent,
    DialogagevalidityComponent,
    DialogIdleTimerComponent,
    RecentComponent,
    MonthComponent,
    CorrespondenceComponent,
    ReminderComponent,
    UserprofileComponent,
    DisclosureComponent,
    DisputesDetailAllComponent,
    CorrespondenceAllComponent,
    DialogFileUploaderComponent,
    PdfViewerComponent,
    AfterLoginMenuOnHeaderComponent,
    AfterLoginTopHeaderComponent,
    ConsumerDisclosureDisputeComponent,
    DialogdisputeComponent,
    DialogdisclosureComponent,
    DialogReportedInfoComponent,
    DialogIndividualDisputeComponent,
    ViewAllDocumentsComponent,
    ManageSecurityFreezeComponent,
    ManageFreezeDetailsComponent,
    SecurityFreezeInfoComponent,
    SecurityFreezeNoticeComponent,
    PlaceFreezeComponent,
    ReplaceSecurityPinComponent,
    DialogwithmessagebtnComponent,
    ConsumerScoreDisclosureReportComponent,
    ConsumerScorePageComponent,
    DisclosureDisputeTransComponent,
    DialogRemFrzComponent,
    DialogSchLiftComponent,
    PhonenumbercontrolComponent,
    OptOutComponent,
    PlaceSecurityAlertComponent,
    ChangePasswordComponent,
    ConsumerDisclosureTransitionComponent,
    IdentityTheftInformationComponent,
    AboutUsComponent,
    ContactUsComponent,
    SitecoresearchComponent,
    SanitizeHtmlPipe,
    AutocompleteOffDirective,
    SnackbarComponent,
    DialogSubmitDisputeTransComponent,
    DialogIdleTimerAfterLoginComponent,
    LoaderComponent,
    CommonSnackbarComponent,
    DialogwithmessageCancelComponent,
    DialogWithMessageCustomButtonComponent,
    DLMaskingDirective,
    HumanTraffickingComponent
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    MatIconModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    RufAppCanvasModule,
    RufLayoutModule,
    RufBannerModule,
    RufFooterModule,
    RufIconModule,
    RufMenubarModule,
    RufNavbarModule,
    RufPageHeaderModule,
    HttpClientModule,
    RufTimePickerModule,
    RufNativeDateTimeModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    RufMaterialModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    RufUniqueIdModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    RufLabeledIconModule,
    FlexLayoutModule,
    MatIconModule,
    RufIconModule,
    RufCardModule,
    ReactiveFormsModule,
    MatStepperModule,
    RufToggleModule,
    RufStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    RufDynamicMenubarModule,
    RufDialogPanelModule,
    BidiModule,
    RufDropdownPanelModule,
    RufTriggerModule,
    RufToolbarModule,
    MatDividerModule,
    MatListModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    AuthModule.forRoot(environment.auth),
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: environment.reCaptchaSiteKey,
      },
      normal: {
        sitekey: environment.reCaptchaSiteKey,
      },
      language: 'en',
    }),
    MatProgressSpinnerModule,
    MatTooltipModule,
    RufCarouselModule,
    RufUploadModule,
    RufAvatarModule,
    MatDividerModule,
    MatMenuModule,
    MatTabsModule,
    RufDynamicDatatableModule,
    MatProgressBarModule,
    MatBadgeModule,
    RufScrollbarModule,
    RufStatusbarModule,
    RufEllipsisModule,
    RufDynamicSideMenuModule,
    MatSidenavModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RufDynamicSegmentModule,
    MatSnackBarModule,
    MatExpansionModule,
    PdfJsViewerModule,
    MatSortModule
  ],
  providers: [
    ApiService,
    SecurityValidationService,
    ReCaptchaV3Service,
    SitecoreService,
    ConfigurationService,
  //   {
  //     provide: APP_INITIALIZER,
  //     useFactory: initApp,
  //     deps: [ConfigurationService, AuthService],
  //     multi: true,
  // },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: OAuthStorage, useClass: MyTokenStore },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
